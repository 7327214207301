import { HttpClient } from '@angular/common/http';
import { Injectable, computed, inject, signal } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, catchError, map, of, tap, throwError } from 'rxjs';

import { UserResponse } from '@dashboard/interfaces/user';
import { environment } from '@env/environment';
import { AuthStatus } from '@auth/enums/auth-status.enum';
import { LoginResponse } from '@auth/interfaces';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _http   = inject( HttpClient );

  private readonly baseUrl: string = environment.baseUrl;

  private _currentUser = signal<UserResponse|null>(null);
  private _authStatus = signal<AuthStatus>( AuthStatus.notAuthenticated );

  public authStatus = computed( () => this._authStatus() );
  public currentUser = computed( () => this._currentUser() );

  constructor() { 
    this.checkAuthStatus().subscribe();
  }

  // Parses a JWT token to be able to extract its content
  parseJwt(token: string): any {
    const base64Url = token.split('.')[1];
    const base64    = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }

  private handleAuthentication(token: string): void {
    const decodedPayload = this.parseJwt(token);
    const user: UserResponse = decodedPayload.user; // Extracts the user from the payload

    this._currentUser.set(user);
    this._authStatus.set(AuthStatus.authenticated);
    localStorage.setItem('token', token);

    const currentDateInMillis = new Date().getTime();
    const expTimeWithBuffer = (decodedPayload.exp * 1000) - 60000;
    if (expTimeWithBuffer <= currentDateInMillis) {
      localStorage.clear();
      this._authStatus.set(AuthStatus.notAuthenticated);
    } else {
      this._authStatus.set( AuthStatus.authenticated );
      localStorage.setItem( 'token', token );
    }
  }


  login(email: string, password: string): Observable<boolean> {
    const url = `${this.baseUrl}/users/auth/login/`;
    const body = { email, password };

    return this._http.post<LoginResponse>(url, body)
      .pipe(
        tap(({ token }) => this.handleAuthentication(token)),
        map(() => true),
        catchError(err => throwError(() => err))
      );
  }

  getAuthToken(): string | null {
    return localStorage.getItem( 'token' );
  }

  checkAuthStatus(): Observable<boolean> {
    const token = this.getAuthToken();

    if (!token) {
      this._authStatus.set(AuthStatus.notAuthenticated);
      return of(false);
    } else {
      this.handleAuthentication(token);
      return of(true);
    }
  }

  updateAuthStatus(status: AuthStatus) {
    this._authStatus.set(status);
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('url');
    this._currentUser.set(null);
    this._authStatus.set( AuthStatus.notAuthenticated );
  }

  getVerificationCode(email: string) {
    const url = `${this.baseUrl}/users/password/code/`;
    const body = {email};

    return this._http.post<{}>(url, body);
  }

  validateVerificationCode(email: string, code: string) {
    const url = `${this.baseUrl}/users/password/code/validate/`;
    const body = {email, code};

    return this._http.post<{}>(url, body);
  }

  changePassword(email: string, code: string, new_password: string) {
    const url = `${this.baseUrl}/users/password/code/change/`;
    const body = {email, code, new_password};

    return this._http.patch<{}>(url, body);
  }

}
