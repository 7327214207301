import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { inject } from '@angular/core';
import { AuthStatus } from '../enums/auth-status.enum';

export const privateGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  const currentUser = authService.currentUser();
  
  if (authService.authStatus() === AuthStatus.authenticated && currentUser && [0, 1, 2].includes(currentUser.user_type)) {
    return true;
  }

  router.navigateByUrl('/auth/login');
  return false;
};