import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { Calendar } from 'primeng/calendar';




platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

Calendar.prototype.getDateFormat = () => 'dd/mm/yy';