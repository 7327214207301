import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from '@auth/services/auth.service';

export const authTokenInterceptor: HttpInterceptorFn = (req, next) => {
  const authToken = inject(AuthService).getAuthToken();
  if (!req.url.startsWith('https://www.datos.gov.co/resource/xdk5-pm3f.json')) {
    if (authToken) {
      const newReq = req.clone({
        headers: req.headers.append('Authorization', `Bearer ${authToken}`),
      });
      return next(newReq);
    }
  }

  return next(req);
};
