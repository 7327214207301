import { Component, OnDestroy, OnInit, effect, inject } from '@angular/core';
import { AuthService } from './auth/services/auth.service';
import { AuthStatus } from './auth/enums/auth-status.enum';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private _authService = inject(AuthService);
  private _router      = inject(Router);
  private _checkAuthStatusInterval!: any;

  ngOnInit() {
    this._authService.checkAuthStatus();
    this._checkAuthStatusInterval = setInterval(() => {
      this._authService.checkAuthStatus();
    }, 60000);
  }

  public authStatusChangedEffect = effect(() => {
    switch (this._authService.authStatus()) {
      case AuthStatus.authenticated:
        break;
      case AuthStatus.notAuthenticated:
        this._router.navigateByUrl('/auth/login');
        break;
    }
  });

  ngOnDestroy(): void {
    clearInterval(this._checkAuthStatusInterval);
  }
}