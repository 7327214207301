import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { inject } from '@angular/core';
import { AuthStatus } from '../enums/auth-status.enum';

export const publicGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  if (authService.authStatus() === AuthStatus.authenticated) {
    const currentUser = authService.currentUser();
    switch (currentUser?.user_type) {
      case 3:
        router.navigateByUrl('/operator');
        break;
      default:
        router.navigateByUrl('/dashboard');
    }
    return false;
  }

  return true;
};