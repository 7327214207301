import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { AuthStatus } from '../enums/auth-status.enum';

export const operatorGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router      = inject(Router);

  const currentUser = authService.currentUser();
  
  if (authService.authStatus() === AuthStatus.authenticated && currentUser?.user_type === 3) {
    return true;
  }

  router.navigateByUrl('/auth/login');
  return false;
};
