import { Injectable, WritableSignal, signal } from '@angular/core';

interface Identifiable {
  id: string;
}

@Injectable({
  providedIn: 'root'
})
export class TableService<T extends Identifiable> {
  public isDataLoading: WritableSignal<boolean> = signal(false);
  public tableDataList: WritableSignal<T[]> = signal([]);
  public selectedData: WritableSignal<T[]> = signal([]);
  public tableColumns: WritableSignal<T[]> = signal([]);


  updateIsDataLoading(isDataLoading: boolean) {
    this.isDataLoading.set(isDataLoading);
  }

  updateTableColumns(columns: any[]) {
    this.tableColumns.set(columns);
  }

  // addElementToTableDataList(elements: T | T[]) {
  //   const elementsToAdd = Array.isArray(elements) ? elements : [elements];
  //   const newDataList = [...elementsToAdd, ...this.tableDataList()];
  //   if (elementsToAdd.length > 0) {
  //       newDataList.splice(-elementsToAdd.length);
  //   }
  //   this.tableDataList.set(newDataList);
  // }

  // updateElementInTableDataList(updatedElement: T, matchFn: (element: T) => boolean) {
  //   const index = this.tableDataList().findIndex(matchFn);
  //   if (index !== -1) {
  //     const newList = [...this.tableDataList()];
  //     newList[index] = updatedElement;
  //     this.tableDataList.set(newList);
  //   } else {
  //     console.error('Element not found for update');
  //   }
  // }


  classifyElementsToCreateOrUpdate(elements: T[]): void {
    const newElements: T[] = [];
    const existingElements: T[] = [];
  
    elements.forEach(element => {
      const index = this.tableDataList().findIndex(existingElement => existingElement.id === element.id);
      if (index !== -1) {
        existingElements.push(element);
      } else {
        newElements.push(element);
      }
    });
  
    if (newElements.length > 0) {
      this.addElementToTableDataList(newElements);
    }
  
    if (existingElements.length > 0) {
      this.updateElementsInTableDataList(existingElements);
    }
  }

  addElementToTableDataList(elements: T | T[]): void {
    const elementsToAdd = Array.isArray(elements) ? elements : [elements];
    let currentDataList = this.tableDataList().slice();
    elementsToAdd.forEach(elementToAdd => {
      if (!currentDataList.find(existingElement => existingElement.id === elementToAdd.id)) {
        currentDataList.unshift(elementToAdd);
      }
    });
    this.tableDataList.set(currentDataList);
  }

  updateElementsInTableDataList(elements: T | T[]): void {
    const elementsToUpdate = Array.isArray(elements) ? elements : [elements];
    let currentDataList = this.tableDataList();
  
    elementsToUpdate.forEach(elementToUpdate => {
      const index = currentDataList.findIndex(existingElement => existingElement.id === elementToUpdate.id);
      if (index !== -1) {
        currentDataList[index] = elementToUpdate;
      }
    });
  
    this.tableDataList.set(currentDataList);
  }


  deleteElementsInTableDataList(deletedElementsIds: string[], matchFn: (elementId: string, element: T) => boolean) {
    const updatedDataList = this.tableDataList().filter(element => 
      !deletedElementsIds.some(elementId => matchFn(elementId, element))
    );
    this.updateTableDataList(updatedDataList);
  }

  updateTableDataList(dataList: T[]) {
    this.tableDataList.set(dataList);
  }

  updateSelectedData(selectedData: T[]) {
    this.selectedData.set(selectedData);
  }
}
