import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient, withInterceptors } from "@angular/common/http";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

import { TableService } from '@dashboard/services/table.service';
import { TABLE_SERVICE_BIN_TOKEN, TABLE_SERVICE_CART_TOKEN, TABLE_SERVICE_DASHBOARD_TOKEN, TABLE_SERVICE_DEVICE_TOKEN, TABLE_SERVICE_POSITION_TOKEN, TABLE_SERVICE_REFERENCE_TOKEN, TABLE_SERVICE_USER_TOKEN } from '@dashboard/injection-tokens/table-injection-tokens';
import { authTokenInterceptor } from '@shared/interceptors/auth-token.interceptor';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
  ],
  providers: [
    DialogService,
    DynamicDialogRef,
    provideHttpClient(withInterceptors([authTokenInterceptor])),
    {
      provide: TABLE_SERVICE_REFERENCE_TOKEN,
      useFactory: () => new TableService<any>()
    },
    {
      provide: TABLE_SERVICE_POSITION_TOKEN,
      useFactory: () => new TableService<any>()
    },
    {
      provide: TABLE_SERVICE_DEVICE_TOKEN,
      useFactory: () => new TableService<any>()
    },
    {
      provide: TABLE_SERVICE_CART_TOKEN,
      useFactory: () => new TableService<any>()
    },
    {
      provide: TABLE_SERVICE_BIN_TOKEN,
      useFactory: () => new TableService<any>()
    },
    {
      provide: TABLE_SERVICE_USER_TOKEN,
      useFactory: () => new TableService<any>()
    },
    {
      provide: TABLE_SERVICE_DASHBOARD_TOKEN,
      useFactory: () => new TableService<any>()
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
