import { InjectionToken } from '@angular/core';
import { BinResponse } from '@dashboard/interfaces/bin';
import { CartResponse } from '@dashboard/interfaces/cart';

import { DeviceResponse } from '@dashboard/interfaces/device';
import { PositionResponse } from '@dashboard/interfaces/position';
import { ReferenceResponse } from '@dashboard/interfaces/reference';
import { UserResponse } from '@dashboard/interfaces/user';
import { DashboardResponse } from '@dashboard/interfaces/wave';
import { TableService } from '@dashboard/services/table.service';

export const TABLE_SERVICE_REFERENCE_TOKEN = new InjectionToken<TableService<ReferenceResponse>>('TableServiceReferenceToken');
export const TABLE_SERVICE_POSITION_TOKEN = new InjectionToken<TableService<PositionResponse>>('TableServicePositionToken');
export const TABLE_SERVICE_DEVICE_TOKEN = new InjectionToken<TableService<DeviceResponse>>('TableServiceDeviceToken');
export const TABLE_SERVICE_CART_TOKEN = new InjectionToken<TableService<CartResponse>>('TableServiceCartToken');
export const TABLE_SERVICE_BIN_TOKEN = new InjectionToken<TableService<BinResponse>>('TableServiceBinToken');
export const TABLE_SERVICE_USER_TOKEN = new InjectionToken<TableService<UserResponse>>('TableServiceUserToken');
export const TABLE_SERVICE_DASHBOARD_TOKEN = new InjectionToken<TableService<DashboardResponse>>('TableServiceDashboardToken');